<template>
  <div style="height: 75vh; width: 50vw; margin-top: 100px;">
    <l-map ref="myMap"
        zoom="9"
        :center="[45.3148,13.7971]"
        @move="log('move')"
    >
<!--      <l-tile-layer-->
<!--          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"-->
<!--                    attribution="Map data &copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors,-->
<!--                    Imagery © <a href='https://www.mapbox.com/'>Mapbox</a>'"-->

<!--      >-->
<!--      </l-tile-layer>-->
      <l-tile-layer
          :url="'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token='+mapboxAccessToken"
          attribution="Map data &copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors,
                    Imagery © <a href='https://www.mapbox.com/'>Mapbox</a>'"

      >
      </l-tile-layer>
      <l-control-layers />
      <l-marker :lat-lng="[0, 0]" draggable @moveend="log('moveend')">
        <l-tooltip>
          lol
        </l-tooltip>
      </l-marker>

      <l-marker :lat-lng="[45.3148,13.7971]">
        <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
      </l-marker>

      <l-marker :lat-lng="[45.342,14.3076]" draggable @moveend="log('moveend')">
        <l-popup>
          lol
        </l-popup>
      </l-marker>

    </l-map>
    <button @click="flyTo">New kitten icon</button>
  </div>
</template>
<script>
import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import {nextTick, onMounted, ref} from "vue";

export default {
name: "Leaflet",
  setup() {
    const mapboxAccessToken = "pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw";
    const myMap = ref(null);
      const flyTo = () => {
        debugger;
        myMap.value.leafletObject.flyTo([45.3148,13.7971],12);
      }
    const map = null;
      const doSomethingOnReady = () => {
        const map = myMap.value.leafletObject;
        flyTo();
      }

      onMounted(() => {
        debugger
        nextTick(()=>{
          console.log(myMap.value)
        });
      });
    return {doSomethingOnReady, myMap, flyTo, mapboxAccessToken}
  },

  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    LPopup,
  },
  data() {
    return {
      zoom: 2,
      iconWidth: 25,
      iconHeight: 40,
    };
  },
  computed: {
    iconUrl() {
      return `https://placekitten.com/${this.iconWidth}/${this.iconHeight}`;
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
  },
  methods: {
    log(a) {
      console.log(a);
    },
    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
  },
}
</script>

<style scoped>

</style>